var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"warehouse-detail"},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('div',{staticClass:"grey lighten-3 py-6"},[_c('v-container',[_c('v-card',{key:_vm.warehouse.warehouseId,attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","xl":"3"}},[_c('div',{staticClass:"pa-6 warehouse-detail-column"},[_c('h1',[_c('img',{attrs:{"src":"/location.svg","alt":"pin negozio"}}),_vm._v(_vm._s(_vm.warehouse.name)+" ")]),_c('div',{staticClass:"mb-4"},[_c('strong',[_vm._v(_vm._s(_vm.warehouse.address.address1)+", "+_vm._s(_vm.warehouse.address.addressNumber)+", "+_vm._s(_vm.warehouse.address.city)+" - ("+_vm._s(_vm.warehouse.address.province)+") "+_vm._s(_vm.warehouse.address.postalcode))])]),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Email: ")]),_c('a',{attrs:{"href":'mailto:' + _vm.warehouse.metaData.warehouse_info.EMAIL}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.EMAIL))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Email delivery: ")]),_c('a',{attrs:{"href":'mailto:' +
                      _vm.warehouse.metaData.warehouse_info.EMAIL_DELIVERY}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.EMAIL_DELIVERY))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',[_c('strong',[_vm._v("Telefono: ")]),_c('a',{attrs:{"href":'tel:' + _vm.warehouse.metaData.warehouse_info.PHONE}},[_vm._v(_vm._s(_vm.warehouse.metaData.warehouse_info.PHONE))])]):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_info.SERVICES_DESCR)}}):_vm._e(),(_vm.warehouse.metaData.warehouse_info)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_info.OPENING_TIMES)}}):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","sm":"8","xl":"9"}},[(_vm.warehouse.warehouseId)?_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
                lat: _vm.warehouse.address.latitude,
                lng: _vm.warehouse.address.longitude
              },"zoom":15,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}}):_vm._e()],1)],1)],1)],1)],1),_c('div',{},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-img',{attrs:{"src":"/img/contact-store.jpg"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-servizio-clienti',{attrs:{"showPrivacy":false,"warehouse-email":_vm.warehouse.metaData.warehouse_info.EMAIL}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }